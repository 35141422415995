import "easymde/dist/easymde.min.css";
import "notyf/notyf.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "antd/dist/antd.css";
import { DefaultLayout } from "layouts/default";
import UserProvider from "context/userContext";

const App = ({ Component, pageProps, err }) => {
  const Layout = Component.Layout || DefaultLayout;

  return (
    <UserProvider>
      <Layout>
        <Component {...pageProps} err={err} />
      </Layout>
    </UserProvider>
  );
};

export default App;
