import {
  useState,
  useCallback,
  createContext,
  useContext,
  useMemo,
} from "react";
import { User } from "~/types/user";

interface UserContextProps {
  user: User;
  setIsUser: any;
  loadingUser: boolean;
}

export const UserContext = createContext({} as UserContextProps);

export default function UserContextComp({ children }): JSX.Element {
  const [user, setUser] = useState<User>(null);
  const [loadingUser, setLoadingUser] = useState(true);

  const setIsUser = (user: User): void => {
    setUser(user);
  };

  const ctxValue = useMemo(
    () => ({
      user,
      setIsUser,
      loadingUser,
    }),
    [user, setIsUser, loadingUser]
  );

  return (
    <UserContext.Provider value={ctxValue}>{children}</UserContext.Provider>
  );
}

export const useUser = (): UserContextProps => useContext(UserContext);
