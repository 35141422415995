import React from "react";
import Head from "next/head";
import { Layout } from "antd";
import { Sidebar } from "components/Sidebar";
import Image from "next/image";

const { Content, Footer, Sider } = Layout;

export const DefaultLayout: React.FC = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <title>戦略ラボ Admin</title>
      </Head>

      <Sider theme="dark" breakpoint="lg" collapsedWidth="0">
        <div className="logo" style={{ padding: 20 }}>
          <Image
            src={"/images/logo.png"}
            alt="戦略デザインラボ"
            width={168}
            height={29}
          />
        </div>
        <Sidebar />
      </Sider>
      <Layout>
        <Content style={{ margin: "24px 16px 0" }}>
          <div style={{ padding: 0, minHeight: 360, backgroundColor: "white" }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }} />
      </Layout>
    </Layout>
  );
};
