import React from "react";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useRouter } from "next/router";
import Link from "next/link";

export const Sidebar: React.FC = () => {
  const router = useRouter();

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["4"]}
      selectedKeys={[router.pathname]}
      defaultOpenKeys={[
        router.pathname.includes("/tactic") && "/tactic",
        router.pathname.includes("/organization") && "/organization",
      ]}
      selectable={false}
    >
      <SubMenu key="/organization" title="組織">
        <Menu.Item key="/jobs">
          <Link href="/jobs">業種</Link>
        </Menu.Item>
        <Menu.Item key="/organizations">
          <Link href="/organizations">会社</Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/gpt_apps">
        <Link href="/gpt_apps">AIアシスタント</Link>
      </Menu.Item>
      <Menu.Item key="/users">
        <Link href="/users">会員</Link>
      </Menu.Item>
      <Menu.Item key="/online_consultation">
        <Link href="/online_consultation">いつでもチャット</Link>
      </Menu.Item>
      <Menu.Item key="/management_sheets">
        <Link href="/management_sheets">戦略見える化シート</Link>
      </Menu.Item>
      <Menu.Item key="/diagnostics">
        <Link href="/diagnostics">診断</Link>
      </Menu.Item>
      <Menu.Item key="/specialists">
        <Link href="/specialists">専門家</Link>
      </Menu.Item>
      <SubMenu key="/tactic" title="戦術">
        <Menu.Item key="/tactic_categories">
          <Link href="/tactic_categories">カテゴリー</Link>
        </Menu.Item>
        <Menu.Item key="/tactic_primaries">
          <Link href="/tactic_primaries">大項目</Link>
        </Menu.Item>
        <Menu.Item key="/tags">
          <Link href="/tags">タグ管理</Link>
        </Menu.Item>
        <Menu.Item key="/tactics">
          <Link href="/tactics">コンテンツ</Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/dailies">
        <Link href="/dailies">Daily</Link>
      </Menu.Item>
      <Menu.Item key="/seminar_archives">
        <Link href="/seminar_archives">セミナーアーカイブ</Link>
      </Menu.Item>
    </Menu>
  );
};
